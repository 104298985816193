.configure-accordion {
  margin-bottom: 8px;

  .aircraft-details-labels {
    height: 30px;
  }

  .aircraft-details-grid {
    max-height: 500px;
    overflow-y: auto;
    position: relative;

    .aircraft-details-row {
      .scuf-col.col-4 {
        padding-bottom: 0;
      }
    }
  }

  .aircraft-details-grid::-webkit-scrollbar {
    width: 10px;
  }

  .aircraft-details-grid::-webkit-scrollbar-thumb {
    background: #606060 !important;
  }

  .aircraft-details-grid::-webkit-scrollbar-track {
    background: #fff;
    border: #d0d0d0 0.0625rem solid;
  }

  .configTitle {
      background: #fff;
      padding-bottom: 1rem;
      font-size: 1.125rem;
      letter-spacing: 0.25px;
      font-weight: 600;
      color: #303030;
    }

    .feature {
      display: flex;
      margin-bottom: 10px; /* Adjust as needed */
    }
    
    .label {
      width: 150px; /* Adjust as needed */
      margin-right: 10px; /* Adjust as needed */
    }
    
    .content {
      flex-grow: 1;
    }

    .aircraft-details-grid .breakLine {
      border: 1px solid rgba(0, 0, 0, 0.75) ;
    }

    .aircraftDetails,.configDetails {
      font-size: 14px !important;
    }

    .configDetails .scuf-col.col-4 {
      padding-bottom: 5px !important;
    }

    .configDetails .softFeatureList {
      font-size: 12px;
    }

    .configDetails .descColPadding {
      padding-bottom: 5px;
    }

    .configDetails .softFeatureDesc{
      padding-left: 50px;
    }

    .gridBoxPadding {
      padding-left: 12px;
    }

    .gridHrBox {
      padding-bottom: 0px;
    }

    .accordionContentPadding {
      margin-left: 12px;
    }

    .purchasedFeatureDropDownBtn {
      max-height: 46px;
    }
    
}
