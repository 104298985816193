.software-features-list-grid {
  padding-left: 24.9px;

  .software-features-list {
    .ui.radio.checkbox {
      margin-right: 44px;
    }

    .ui.scuf-dropdown-wrapper {
      width: 100%;
    }
  }
}

.software-feature-header {
  width: 100% !important;
  height: 25px;
}

.incompatible-msg {
  color: red;
  font-size: 12px;
}

.software-display-block {
  display: block;
}
